import React, { useState } from 'react';
import loadable from '@loadable/component';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from "history";
import './App.css';

const CompletedPage = loadable(() => import('./components/Report/CompletedPage'));
const CreateSurvey = loadable(() => import('./components/CreateSurvey/CreateSurvey'));
const SurveyPage = loadable(() => import('./components/Survey/Survey'));
const Home = loadable(() => import('./components/Home/Home'));

const history = createBrowserHistory();



export default function App () {
    const [completed, setCompleted] = useState(false);
    const [surveyResult, setSurveyResult] = useState('');
    const [selectedParameters, setSelectedParameters] = useState([]);
    const [behaviouralQuestions, setBehaviouralQuestions] = useState([]);

    const primaryColor = "#ec6623";

    const clientData = {
        "client": "hays",
        selectedParameters,
        "CompanyShwartzScore": 60,
        "companyClasses": {
            "A": 7,
            "A1": 7,
            "A2": 7,
            "A3": 7,
            "A4": 7,
            "A5": 7,
            "C": 7,
            "C1": 7,
            "C2": 7,
            "C3": 7,
            "C4": 7,
            "C5": 7,
            "E": 7,
            "E1": 7,
            "E2": 7,
            "E3": 7,
            "E5": 7,
            "E6": 7,
            "N": 7,
            "N1": 7,
            "N2": 7,
            "N3": 7,
            "N4": 7,
            "N6": 7,
            "O": 7,
            "O1": 7,
            "O2": 7,
            "O3": 7,
            "O4": 7,
            "O5": 7
        }
    };




    return (
        <Router history={history}>
            <Switch>

                <Route path="/create" >
                    <CreateSurvey />
                </Route>
                <Route path="/fitcheck" >
                    <SurveyPage completed={completed} setSurveyResult={setSurveyResult} setCompleted={setCompleted} primaryColor={primaryColor} behaviouralQuestions={behaviouralQuestions} />
                </Route>

                <Route path="/report" >
                    <CompletedPage surveyResult={surveyResult} clientData={clientData} />
                </Route>

                <Route exact path="/home/*" >
                    <Home setSelectedParameters={setSelectedParameters} setBehaviouralQuestions={setBehaviouralQuestions} selectedParameters={selectedParameters} behaviouralQuestions={behaviouralQuestions} />
                </Route>

            </Switch>
        </Router>
    );
}


